var Dali = {
	s: {
			plusImg: '<img src="assets/img/plus.svg" />',
			plusClass: 'plus',
			points: false,
			slideMargin: 30,
			started: false,
			fadeOutScale: 25,
			fadeInScale: 0.04,
			fadeDelay: 1500,
			disabledPointClass: 'disabled'
	},

	init: function(item) {
		var points, self = this, s = this.s;

		this.setSound();

		s.activeContainer = item;
		s.activeImg = item.children('img');

		// Turn data-points string into array
		points = s.activeImg.data('points');

		if(typeof points !== 'object') {
			points = points.split(',');
		}

		// Turn array into multi-layered array for ease of use
		s.activePoints = self.translateDataString(points);

		// Create points on image
		self.createPoints(s.activePoints);

		// Bind UI Actions once the points are created
		self.bindUIActions();
	},

	setSound: function() {
		this.s.sound = new Audio();
		this.s.sound.src = window.location.href + 'assets/sound/mimesis.mp3';
		this.s.sound.loop = false;
	},

	setTransformOrigin: function(img, points) {
		img.css({
			'-webkit-transform-origin':  	points[1] + '% ' + points[2] + '%',
			'-ms-transform-origin':				points[1] + '% ' + points[2] + '%',
			'transform-origin': 					points[1] + '% ' + points[2] + '%'
		});
	},

	bindUIActions: function() {
		var i, point, self = this;

		for(i = 0; i < this.s.points.length; i++) {
			point = this.s.points[i];

			point.ele.on('click', {point: point}, function(e) {
				e.stopPropagation();

				if(!$(this).hasClass(self.s.disabledPointClass)) {
					self.open(e.data.point);
				}
			});

			point.ele.on('mouseover', {point: point}, function(e) {
				self.setTransformOrigin(self.s.activeImg, e.data.point.point);
				self.s.sound.play();
			});
		}
	},

	open: function(point) {
		var nextContainer, nextImg, self = this;

		// Open nieuwe afbeelding
		$.ajax({
			url: window.location.href + 'slide',
			type: 'Post',
			data: {
				page: point.point[3],
				image: point.point[0]
			},
			success: function(data) {
				nextContainer = $(data);
				nextImg = nextContainer.children('img');

				$('body').append(nextContainer);
			},
			complete: function(msg) {
					self.disablePoints();

					nextImg.on('load', function() {
						self.fadeOut(self.s.activeImg, point);
						self.fadeIn(nextImg, self.s.activeImg, point);
						self.removePoints();
						if(nextImg.data('word')) popup.addBreadCrumb(nextImg.data('word'));

						setTimeout(function() {
							// After animation
							self.init(nextContainer);
							self.s.started = true;
						}, self.s.fadeDelay);
					});

			},
			error: function(msg) {
				console.log('error');
				console.log(msg);
			}
		});
	},

	fadeOut: function(img, point) {
		var left, css, top, offsetTop, offsetLeft, self = this;

		left = this.getLeft(point.point[1], img);
		top = this.getTop(point.point[2], img);

		offsetLeft = left - ($(window).width() / 2);
		offsetTop = top - ($(window).height() / 2);

		left = img.offset().left - offsetLeft;
		top = img.offset().top - offsetTop;

		css = 'translate3d(' + left + 'px, ' + top + 'px, 0) scale('+ self.s.fadeOutScale +')';

		img.css({
			'-webkit-transform': 	css,
			'-ms-transform': 			css,
			'transform': 					css,
		});
	},

	fadeIn: function(nextImg, prevImg, point) {
		this.positionSlide(nextImg);

		var x, y, css,
				imgCenter = {},
				pointCenter = {},
				self = this,
				imgOffset = nextImg.offset(),
				pointOffset = point.ele.offset(),
				imgFullSize = this.getFullImgSize(nextImg);

		imgCenter.x = $(window).width() / 2;
		imgCenter.y = $(window).height() / 2;

		pointCenter.x = pointOffset.left + point.ele.width();
		pointCenter.y = pointOffset.top + point.ele.height();

		x = pointCenter.x - imgCenter.x;
		y = pointCenter.y - imgCenter.y;

		css = 'translate3d(' + x + 'px, ' + y + 'px, 0) scale(' + self.s.fadeInScale + ')';

		this.transitionlessAnimation(nextImg, {
			'-webkit-transform': 	css,
			'-ms-transform': 			css,
			'transform': 					css
		});

		css = 'translate3d(0,0,0) scale(1)';

		nextImg.css({
			'-webkit-transform': 	css,
			'-ms-transform': 			css,
			'transform': 					css,
			'opacity': '1'
		});
	},

	transitionlessAnimation: function(item, css) {
		item.addClass('no-transition');
		item.css(css);
		item[0].offsetHeight;
		item.removeClass('no-transition')
	},

	createPoints: function(points) {
		var i, point, left, top, element;

		this.s.points = [];

		for (i = 0; i < points.length; i++) {
			point = points[i];
			element = {};

			left = this.getLeft(point[1], this.s.activeImg);
			top = this.getTop(point[2], this.s.activeImg);

			element.ele = $('<div/>').attr({
				'class': 			'plus',
				'style':			'left:' + left + 'px; top:' + top + 'px;' 
			});

			element.ele.append(this.s.plusImg);
			element.point = point;
			$('body').append(element.ele);

			this.s.points.push(element);
		}
	},

	removePoints: function() {
		var i, point;

		for(i = 0; i < this.s.points.length; i++) {
			point = this.s.points[i];

			point.ele.remove();
		}
	},

	disablePoints: function() {
		var i, point;

		for(i = 0; i < this.s.points.length; i++) {
			point = this.s.points[i];

			point.ele.addClass(this.s.disabledPointClass);
		}
	},

	repositionPoints: function() {
		var i, point, self = this;

		for(i = 0; i < this.s.points.length; i++) {
			point = this.s.points[i];

			point.ele.css({
				'left': self.getLeft(point.point[1], self.s.activeImg) + 'px',
				'top':  self.getTop(point.point[2], self.s.activeImg) + 'px'
			});
		}
	},

	getLeft: function(position, image) {
		var offset, relativePosition;

		offset = image.offset().left;
		relativePosition = image.width() / 100 * position;

		return offset + relativePosition;
	},

	getTop: function(position, image) {
		var offset, relativePosition;

		offset = image.offset().top;
		relativePosition = image.height() / 100 * position;

		return offset + relativePosition;
	},

	translateDataString: function(points) {
		var i, item, newPoints = [], point = [];

		for(i=0; i<points.length; i++) {
			point.push(points[i]);

			if(i % 4 == 3) {
				newPoints.push(point);
				point = [];
			}
		}

		return newPoints;
	},

	getFullImgSize: function(img) {
		var windowRatio, imgRatio, imgWidth, imgHeight,
				windowWidth = $(window).width() - 2 * this.s.slideMargin,
				windowHeight = $(window).height() - 2 * this.s.slideMargin;

		windowRatio = windowWidth / windowHeight;
		imgRatio = img.width() / img.height();

		if(imgRatio < windowRatio) {
			// Higher image
			imgHeight = windowHeight;
			imgWidth = windowHeight * imgRatio;
		} else {
			// Wider image
			imgWidth = windowWidth;
			imgHeight = windowWidth / imgRatio;
		}

		return {
			width: imgWidth,
			height: imgHeight
		};
	},

	positionSlide: function(img) {
		var imgSize = this.getFullImgSize(img);

		img.css({
			width: imgSize.width + 'px',
			height: imgSize.height + 'px',
			left: (($(window).width() - imgSize.width) / 2) + 'px',
			top: (($(window).height() - imgSize.height) / 2) + 'px'
		});
	},

	close: function() {
		$('.slide').remove();
		$('.plus').remove();
	}
};

(function() {
	$(window).on('resize', function() {
		if(Dali.s.started) {
			Dali.positionSlide(Dali.s.activeImg);
		}

		if(Dali.s.points !== false) {
			Dali.repositionPoints();
		}
	});
})();