(function() {
	var container = $('#indicator'),
			x = {
				ele: container.children('.x')
			},
			y = {
				ele: container.children('.y')
			};

	$(document).on('mousemove', function(e) {
		x.ele.html(e.pageX);
		y.ele.html(e.pageY);

		container.css('transform', 'translate3d(' + e.pageX + 'px, ' + e.pageY + 'px, 0)');
	});
})();