(function() {
	var isTouch, masonry, o, popup, openers = [];

	/// 1.0
	/// Define Helpers
	isTouch = function() {
		return (window.navigator.msMaxTouchPoints || ('ontouchstart' in document.documentElement));
	}

	/// 2.0
	/// Define Settings


	/// 3.0
	/// Initialize Masonry
	masonry = $('.masonry').masonry({
		itemSelector: '.sentence'
	});

	/// 4.0
	/// Initialize Homepage backgrounds
	if($('a.opener--bg')) {
		$('a.opener--bg').each(function() {
			o = $.opener({
				'btn': $(this),
				'openEvent': 'mouseover',
				'closeEvent': 'mouseout',
				'target': $(this),
				'onOpen': function() {
					var html,
							that = this;

					html = '<div class="background-image"><img ';
					html += 'data-points="' + this.btn.data('points') + '" ';
					html += 'src="' + this.btn.data('src') + '"';
					html += '" /></div>';

					$('body').prepend(html);
					$('body').addClass(this.openClass);
				},

				'onClose': function() {
					if(!$('div.background-image').hasClass('stay')) {
						$('div.background-image').remove();
						$('body').removeClass(this.openClass);
					}
				}
			});

			openers.push(o);
		});
	}
})();