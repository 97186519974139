(function() {
	/// Object for opening and closing the popups
	popup = {
		init: function() {
			this.bindUIActions();

			this.openAlternate($('.opener--alternate').data('url'));
		},

		bindUIActions: function() {
			var popup_url, sentence, title, that = this;

			$('li.sentence a').each(function() {
				if(!$(this).hasClass('opener--alternate')) {
					// Regular popup
					$(this).on('click', function(e) {
						// e.preventDefault();
						e.stopPropagation();
						popup_url = $(this).data('url');
						sentence = $(this).parent();
						word = $(this);

						$('div.background-image').addClass('stay');
						$(this).addClass('active');

						Dali.init($('div.background-image'));

						that.open(popup_url, sentence, word);
					});
				} else {
					$(this).on('click', function(e) {
						e.stopPropagation();
						popup_url = $(this).data('url');
						$('div.background-image').addClass('stay');

						that.openAlternate(popup_url);
					});
				}
			});
		},

		getSentece: function(number) {
			return $('#sentence--' + number);
		},

		initPopup: function(word) {
			var that = this;
			this.popup = $('#popup');
			this.addBreadCrumb(word);

			$('.popup__close').on('click', function(e) {
				e.stopPropagation();
				that.close();
			});

			$('#popup').on('click', function(e) {
				e.stopPropagation();
			});

			// $('body').on('click', function() {
			// 	that.close();
			// });
			
			$('.popup__open').on('click', function() {
				$('#popup').addClass('active');
			});

			$('.popup__close--info').on('click', function() {
				$('#popup').removeClass('active');
			});

			// setTimeout(function() {
			// 	that.popup.addClass('active');
			// }, 10);
		},

		addBreadCrumb: function(word) {
			var html = '';

			html = ' > <a class="breadcrumb">';

			if(typeof word == 'object') {
				html += word.html();
			} else {
				html += word;
			}

			html += '</a>';

			$('.menu').append(html);

			$('.breadcrumb').last().on('click', {word: word}, function(e) {
				e.data.word.click();
			});

			var menu = document.getElementById("menu");
			menu.scrollTop = menu.scrollHeight;
		},

		initUIActions: function() {
			// var that = this;

			// $('#popup').on('click', function() {
			// 	that.closeAni();
			// });

			// this.popup.on('click', function(e) {
			// 	e.stopPropagation();
			// });

			// $('#close').on('click', function() {
			// 	that.closeAni();
			// })
		},

		open: function(detail_url, sentence, word) {
			var that = this;

			$.ajax({
				url: detail_url,
				type: 'GET',
				context: document.body,
				success: function(data) {
					$('body').append(data);
					$('body').addClass('popup--opened');
					that.initPopup(word);
				}
			});

			this.activateSentence(sentence);
		},

		openAlternate: function(detail_url) {
			var that = this;

			$.ajax({
				url: detail_url,
				data: {
					alternate: true
				},
				type: 'Post',
				context: document.body,
				success: function(data) {
					$('body').append(data);

					$('body').on('click', function() {
						$('.alternate-popup').remove();
						$('div.background-image').remove();
					});

					$('.popup__close').on('click', function() {
						$('.alternate-popup').remove();
						// that.deActivateSentence();
						$('div.background-image').remove();
					});

				}
			});
		},

		activateSentence: function(sentence) {
			$('.sentence').each(function() {
				$(this).removeClass('active');
			});

			sentence.addClass('active');
		},

		deActivateSentence: function() {
			$('.sentence.active').each(function() {
				$(this).removeClass('active');
			});
		},
		
		getQueryVariable: function(variable) {
			var query = window.location.search.substring(1);
			var vars = query.split("&");
			for (var i=0;i<vars.length;i++) {
			       var pair = vars[i].split("=");
			       if(pair[0] == variable){return pair[1];}
			}
			return(false);
		},

		close: function() {
			var popup = this.popup;
			this.deActivateSentence();
			$('div.background-image').remove();
			$('.popup__close').remove();	
			$('li.sentence a.active').removeClass('active');
			$('body').removeClass('popup--opened');
			popup.removeClass('active');
			$('.popup__open').remove();

			$('body').off('click');

			// setTimeout(function() {
				popup.remove();

				Dali.close();
			// }, 200);
		}
	};


	/// xx.xx
	/// Window Ready functions
	$(window).ready(function() {
		popup.init();
		// popup.open('popup.php');
	});
})();